<template>
  <div class="product">
    <v-parallax
      dark
      style="max-height:120px; width:100% !important;box-shadow: 0 5px 15px rgba(0, 0, 0, .5), 0 -5px 15px rgba(0, 0, 0, .5);"
      :src="require('../assets/Soldermask_01.jpeg')"
    >
      <v-row align="center" justify="center">
        <div class="d-flex white-filter" />
        <v-col class=" d-flex text-center flex-column align-center" cols="12">
          <h1
            class="header-fg-alt thin white--text mb-4 neon"
            style="background-color: rgba(0, 0, 0, 0.6);width:450px"
          >
            DETALLES
          </h1>
          <div style="width:350px; height:1px; background-color: white; " />
        </v-col>
      </v-row>
    </v-parallax>
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col xs="12" sm="11" md="10" lg="8" xl="7">
          <v-row>
            <v-col cols="5">
              <v-img :src="image" />
            </v-col>
            <v-col class="pl-5 pt-10">
              <v-row>
                <v-col>
                  <h1 class="header-1-alt">{{ this.data.name }}</h1>
                  <p class="header-2-alt bold">${{ this.data.price }}</p>
                  <span class="paragraph">Categoría: {{ "  " + this.data.category }}</span>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col>
                  <h1 class="header-3-alt bold">DESCRIPCIÓN</h1>
                  <p>{{ this.data.description }}</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-overlay
      color="white"
      z-index="2000"
      :value="loader"
      class="d-flex justify-center align-center"
    >
      <lottie-animation
        style="width:250px;height:250px;"
        data-aos="fade-up"
        class="ma-0"
        path="../hack-loader.json"
        :loop="true"
        :autoPlay="true"
        :speed="1.4"
      />
    </v-overlay>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import config from "../config";
import Api from "../service/api";
export default {
  name: "Product",
  components: { LottieAnimation },
  data() {
    return {
      data: null,
      loader: false
    };
  },
  computed: {
    image() {
      if (this.data !== null) {
        return this.data.image !== undefined ? this.data.image : config.images.noImage;
      }
    },
    async loadData() {
      this.showLoader(true);
      let response = await Api().get("/stock/getProduct/" + this.$route.params.id);
      if (response.data.confirmation) {
        this.data = response.data.data;
        this.showLoader(false);
      } else this.showLoader(false);
    }
  },
  methods: {
    showLoader: function(show) {
      this.loader = show;
    }
  },
  beforeMount: function() {
    this.loadData;
  },
  watch: {
    "$route.params.id": {
      handler: function(search) {
        this.loadData;
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
<style lang="scss" scoped>
.product {
}
</style>
