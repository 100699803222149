export default {
  categories: {
    "Circuitos Integrados":
      "https://res.cloudinary.com/hmm7biyrp/image/upload/v1625616429/circuitosintegrados.jpg",
    Leds: "https://res.cloudinary.com/hmm7biyrp/image/upload/v1625616430/leds.jpg",
    Microcontroladores:
      "https://res.cloudinary.com/hmm7biyrp/image/upload/v1625616430/microcontroladores.jpg",
    Motores: "https://res.cloudinary.com/hmm7biyrp/image/upload/v1625616430/motores.jpg",
    "Módulos Arduino":
      "https://res.cloudinary.com/hmm7biyrp/image/upload/v1625616430/modulosarduino.jpg",
    Sensores: "https://res.cloudinary.com/hmm7biyrp/image/upload/v1625616430/sensores.jpg",
    Switches: "https://res.cloudinary.com/hmm7biyrp/image/upload/v1625616431/switches.jpg",
    Transistores: "https://res.cloudinary.com/hmm7biyrp/image/upload/v1625616431/transistores.jpg"
  },
  images: {
    noImage: require("../assets/not-image.png")
  }
};
