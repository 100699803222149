import Vue from "vue";
import VueRouter from "vue-router";
import { Home, About, Categories, Category, Product, Search } from "../views/";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Inicio",
    component: Home
  },
  {
    path: "/categorias",
    name: "Categorías",
    component: Categories
  },
  {
    path: "/categoria/:category",
    name: "Categoría",
    component: Category,
    params: true
  },
  {
    path: "/buscar/:search",
    name: "Buscar",
    component: Search,
    params: true
  },
  {
    path: "/producto/:id",
    name: "Producto",
    component: Product,
    params: true
  },
  {
    path: "/about",
    name: "About",
    component: About
  }
];

const router = new VueRouter({
  routes
});

export default router;
