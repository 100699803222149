<template>
  <div class="home">
    <v-container fluid class="pa-0">
      <v-parallax
        dark
        style="max-height:120px; width:100% !important;box-shadow: 0 5px 15px rgba(0, 0, 0, .5), 0 -5px 15px rgba(0, 0, 0, .5);"
        :src="require('../assets/3.jpg')"
      >
        <v-row align="center" justify="center">
          <div class="d-flex white-filter" />
          <v-col class=" d-flex text-center flex-column align-center" cols="12">
            <h1
              class="header-fg-alt thin white--text mb-4 neon"
              style="background-color: rgba(0, 0, 0, 0.6);width:450px"
            >
              ELECTRÓNICA
            </h1>
            <div style="width:350px; height:1px; background-color: white; " />
            <!-- <v-btn class="ma-2" outlined color="white">
              Ver más
            </v-btn> -->
          </v-col>
        </v-row>
      </v-parallax>
    </v-container>

    <v-container class="mt-7">
      <v-row> <h3 class="header-1-alt">CATEGORÍAS</h3> </v-row>
      <v-row class=" mt-2">
        <v-breadcrumbs class="px-0" :items="items" divider="/"></v-breadcrumbs>
      </v-row>
      <v-layout row wrap class="mt-8">
        <v-flex
          xs12
          sm6
          md4
          lg3
          xl2
          class="align-center justify-center d-flex"
          v-for="category in categories"
          :key="category.name"
        >
          <CategoryCell :video="category" data-aos="fade-up" />
        </v-flex>
      </v-layout>
    </v-container>
    <v-overlay
      color="white"
      z-index="2000"
      :value="loader"
      class="d-flex justify-center align-center"
    >
      <lottie-animation
        style="width:250px;height:250px;"
        data-aos="fade-up"
        class="ma-0"
        path="../hack-loader.json"
        :loop="true"
        :autoPlay="true"
        :speed="1.4"
      />
    </v-overlay>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { CategoryCell } from "../components";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
export default {
  name: "Categories",
  components: { CategoryCell, LottieAnimation },
  computed: {
    ...mapState(["categories"])
  },
  data: () => ({
    loader: false,
    items: [
      {
        text: "Inicio",
        disabled: false,
        to: "/"
      },
      {
        text: "Categorías",
        disabled: false,
        to: "categorias"
      }
    ]
  }),
  methods: {
    showLoader: function(value) {
      this.loader = value;
    }
  },
  watch: {
    categories: function() {}
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/texts";
</style>
