<template>
  <v-card
    :to="{ name: 'Producto', params: { id: video._id } }"
    outlined
    hover
    width="250px"
    class="pb-2 ma-2"
  >
    <v-img :src="image" style="opacity:.8" />
    <v-card-title class="header-4-alt uppercase d-flex">
      <div style="flex:2" class="d-flex flex-column">
        <span class="cell-title">{{ this.video.name }}</span>
        <span class="header-6-alt">{{ this.video.category }}</span>
      </div>
      <div style="flex:1;" class="d-flex align-end flex-column">
        <!-- <span class="header-6-alt">PRICE</span> -->
        <span class="light-titles black--text">${{ this.video.price }}</span>
      </div>
    </v-card-title>
    <v-card-actions class="d-flex ml-2">
      <!-- <span v-for="videoTag in video.tagIds" :key="videoTag">
        <v-btn
          small
          @mousedown.stop
          color="green lighten-2"
          class="mr-2"
          :to="{ name: 'tag', params: { id: videoTag } }"
        >
          {{ getTag(videoTag).name }}
        </v-btn>
      </span> -->
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import config from "../config";
export default {
  name: "CategoryCell",
  computed: {
    image() {
      console.log(this.video.image);
      return this.video.image !== undefined ? this.video.image : config.images.noImage;
    }
  },
  props: {
    video: Object
  }
};
</script>
<style scooped lang="scss">
@import "@/assets/styles/texts";
.cell-title {
  width: 165px;
  max-height: 23px;
  overflow: hidden;
}
</style>
