<template>
  <v-card
    :to="{ name: 'Categoría', params: { category: video.name } }"
    outlined
    hover
    width="250px"
    class="pb-2 ma-2"
  >
    <v-img :src="video.image" style="opacity:.8" />
    <v-card-title class="header-4-alt uppercase">{{ this.video.name }}</v-card-title>
    <v-card-actions class="d-flex ml-2">
      <!-- <span v-for="videoTag in video.tagIds" :key="videoTag">
        <v-btn
          small
          @mousedown.stop
          color="green lighten-2"
          class="mr-2"
          :to="{ name: 'tag', params: { id: videoTag } }"
        >
          {{ getTag(videoTag).name }}
        </v-btn>
      </span> -->
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "CategoryCell",
  props: {
    video: Object
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/texts";
</style>
