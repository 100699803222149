import Vue from "vue";
import Vuex from "vuex";
import Api from "../service/api";
import config from "../config";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    categories: [],
    cart: [
      {
        _id: "606d4f54a00fd72835e6d981",
        name: "Led Amarillo",
        price: 5,
        category: "Leds",
        qty: 2
      },
      {
        _id: "606d5075a00fd72835e6d985",
        name: "NE555",
        price: 5,
        category: "Circuitos Integrados",
        qty: 2,
        image: "https://res.cloudinary.com/hmm7biyrp/image/upload/v1617781031/NES55_lymmfv.jpg"
      }
    ]
  },
  mutations: {
    SET_CATEGORIES(state, categories) {
      let categorias = [];
      categories.forEach((category, i) => {
        console.log(category);
        categorias.push({
          name: category,
          image: config.categories[category]
        });
      });
      state.categories = categorias;
    },
    SET_PRODUCTS(state, data) {
      state.products = [...new Set([...products, ...data])];
    }
  },
  actions: {
    async loadCategories({ commit }) {
      let response = await Api().get("/stock/getCategories");
      console.log(response.data);
      if (response.data.confirmation) {
        let categorias = response.data.data;
        commit("SET_CATEGORIES", categorias);
      }
    }
  },
  modules: {},
  getters: {}
});
