<template>
  <div class="d-flex" color="transparent">
    <div class="d-flex align-center">
      <v-img
        class="shrink mr-0"
        contain
        :src="require('../../assets/Hacktroniks.svg')"
        transition="scale-transition"
        height="55"
      />
    </div>
    <router-link class="Title ml-1" to="/">
      <h2>HACKTRONICKS</h2>
    </router-link>
    <v-spacer></v-spacer>
    <v-scroll-x-reverse-transition :hide-on-leave="true">
      <div class="options pr-3" v-show="!isSearching">
        <router-link :class="getSelected('Inicio')" class="link-container px-4" to="/">
          Inicio
        </router-link>
        <router-link :class="getSelected('Categoría')" class="link-container px-4" to="/categorias">
          Catálogo
        </router-link>
        <router-link :class="getSelected('Catálogo')" class="link-container px-4" to="/">
          Cotizaciones
        </router-link>
        <v-icon
          class="link-container px-4 not-selected"
          v-text="'mdi-magnify'"
          @click="isSearching = true"
        />
        <v-badge color="green" :content="cart.length">
          <v-icon style="width:24px" v-text="'mdi-cart'" @click="showCart(true)" />
        </v-badge>
      </div>
    </v-scroll-x-reverse-transition>
    <v-scroll-x-reverse-transition :hide-on-leave="true">
      <div class="options" v-show="isSearching">
        <v-text-field
          v-model="search"
          solo
          dense
          hide-details
          dark
          rounded
          filled
          placeholder="Buscar"
          append-icon="mdi-close"
          @click:append="isSearching = false"
        />
      </div>
    </v-scroll-x-reverse-transition>
  </div>
</template>
<script type="text/javascript">
export default {
  name: "NavBar",
  data() {
    return {
      isSearching: false
    };
  },
  props: {
    showCart: Function
  },
  methods: {
    getSelected: function(name) {
      return this.$route.name === name ? "selected" : "not-selected";
    }
  }
};
</script>
<style lang="scss" scoped>
.options {
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 380px;
}
.link-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: white !important;
  font-family: Oxygen;
}
.selected {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: white;
}
.not-selected {
}
.not-selected:hover {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: rgb(102, 170, 223);
  border-top-width: 2px;
  border-top-style: solid;
  border-top-color: #272727;
}
</style>
