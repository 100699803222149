<template>
  <div class="home">
    <div class="carousel-container">
      <v-carousel height="450" cycle hide-delimiter-background show-arrows-on-hover>
        <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src" class="carousel-item">
          <v-sheet class="carousel-item">
            <h1 class="carousel-text">{{ item.text }}</h1>
            <div v-html="item.btn" />
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </div>
    <v-container fluid class="pa-0">
      <v-row style="position:relative">
        <v-col class="d-flex justify-center" style="height:300px">
          <h1 class="header-fg" style="z-index:100; margin-top:120px;">
            Servicio de impresiones <span style="font-weight:300">3D</span>
          </h1>

          <lottie-animation
            style="width:400px;height:400px; position: absolute; top:-40px; opacity:.4"
            data-aos="fade-up"
            class="ma-0"
            path="../design.json"
            :loop="false"
            :autoPlay="true"
            :speed="1.4"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-center mt-3">
          <h1 data-aos="fade-up" class="header-1-alt">-TIPOS DE MATERIALES-</h1>
        </v-col>
      </v-row>
      <v-row class="mt-4" justify="center">
        <v-col cols="3">
          <v-card data-aos="fade-right" hover outlined width="350">
            <v-img height="350" :src="require('../assets/PLA.jpeg')" />
            <v-card-actions>
              <span class="header-4">Filamento <span style="font-weight:400">PLA</span></span>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card data-aos="fade-left" hover outlined width="350">
            <v-img height="350" width="350" :src="require('../assets/PETG.jpeg')" />
            <v-card-actions>
              <span class="header-4">Filamento <span style="font-weight:400">PETG</span></span>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="3">
          <v-card data-aos="fade-right" hover outlined width="350">
            <v-img height="350" width="350" :src="require('../assets/ABS.jpeg')" />
            <v-card-actions>
              <span class="header-4">Filamento <span style="font-weight:400">ABS</span></span>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card data-aos="fade-left" hover outlined width="350">
            <v-img height="350" width="350" :src="require('../assets/TPU.jpeg')" />
            <v-card-actions>
              <span class="header-4">Filamento <span style="font-weight:400">TPU</span></span>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row style="position:relative" class="mt-10">
        <v-col class="d-flex justify-center" style="height:300px">
          <h1
            data-aos="fade-up"
            class="header-1-alt text-center"
            style="z-index:100; margin-top:120px;"
          >
            -NUESTRO EQUIPO-
            <p class="text-center header-3-alt light">
              Contamos con las mejores impresoras 3D en el mercado
              <!-- <br />
              con personal especializado  -->
              <br />
              para ofrecerte la mejor calidad en Ensenada.
            </p>
          </h1>

          <lottie-animation
            style="width:400px;height:400px; position: absolute; top:-40px; opacity:.1;"
            data-aos="fade-down"
            class="ma-0"
            path="../3dprint.json"
            :loop="false"
            :autoPlay="true"
            :speed="1"
          />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="4" class="mt-2">
          <v-card data-aos="fade-right" hover outlined class="mx-auto" max-width="400">
            <v-card-title>
              <v-img height="700px" :src="require('../assets/prusa.png')" />
            </v-card-title>
            <v-card-text class="mt-4">
              <h2 class="display-1 text-decoration-underline">- PRUSA MK3S+</h2>
              <p class="thin-light-header-4 boold ml-3 mt-3">
                Siendo 4 años consecutivos la ganadora del premio a la mejor impresora 3D en el
                mercado.
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4" class="mt-2">
          <v-card data-aos="fade-left" outlined hover class="mx-auto" max-width="400">
            <v-card-title>
              <v-img height="700px" :src="require('../assets/sidewinder-.jpg')"> </v-img>
            </v-card-title>
            <v-card-text class="mt-4 ">
              <h2 class="display-1 text-decoration-underline">- Artillery X1 V4</h2>
              <p class="thin-light-header-4 boold ml-3 mt-3">
                Posicionada en el 4to lugar de las mejores impresoras 3D en el mercado. Una calidad
                en cada capa que no puedes comparar.
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col data-aos="fade-up" cols="12" align="center" style="padding-top:85px">
          <p class="header-1-alt">-COTIZA CON NOSOTROS-</p>
          <v-btn
            outlined
            target="_blank"
            style="letter-spacing:4px !important"
            class="pa-6"
            href="https://forms.gle/cuFyjuaR1AFZuetMA"
          >
            SOLICITAR COTIZACIÓN
          </v-btn>
        </v-col>
      </v-row>
      <v-parallax
        dark
        style="margin-top:85px; max-height:200px; width:100% !important;box-shadow: 0 5px 15px rgba(0, 0, 0, .5), 0 -5px 15px rgba(0, 0, 0, .5);"
        :src="require('../assets/Circuito.jpg')"
      >
        <v-row align="center" justify="center">
          <div class="d-flex white-filter" />
          <v-col class=" d-flex text-center flex-column align-center" cols="12">
            <h1
              class="header-fg-alt thin white--text mb-4 neon"
              style="background-color: rgba(0, 0, 0, 0.6);width:450px"
            >
              ELECTRÓNICA
            </h1>
            <div style="width:350px; height:1px; background-color: white; " />
            <!-- <v-btn class="ma-2" outlined color="white">
            Ver más
          </v-btn> -->
          </v-col>
        </v-row>
      </v-parallax>
    </v-container>
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          dark
          v-bind="attrs"
          v-on="on"
          class="ma-2"
          fab
          target="_blank"
          large
          href="https://api.whatsapp.com/send?phone=5216462128283"
          color="rgb(74,206,101)"
          style="z-index:100; position:fixed; bottom:25px; right:25px;"
        >
          <v-icon large>mdi-whatsapp</v-icon>
        </v-btn>
      </template>
      <span class="header-4-alt white--text">Contáctanos!</span>
    </v-tooltip>
  </div>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
export default {
  name: "Home",
  components: { LottieAnimation },
  data() {
    return {
      items: [
        {
          text: "Proyectos de Ingeniaría",
          src: require("../assets/1.jpg"),
          btn: '<button class="_button-4">VER MÁS</button>'
        },
        {
          text: "Decoración",
          src: require("../assets/2.jpg"),
          btn: '<button class="_button-4">VER MÁS</button>'
        },
        {
          text: "Componentes Electrónicos",
          src: require("../assets/componentes.jpg"),
          btn: '<button class="_button-4">VER MÁS</button>'
        },
        {
          text: "Componentes Electrónicos",
          src: require("../assets/ardprom1.png"),
          btn: '<button class="_button-4">VER MÁS</button>'
        }
      ]
    };
  }
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/texts";

.carousel-container {
  height: 450px;
  width: 100%;
}
.carousel-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  .carousel-text {
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    font-family: "Lato";
    font-weight: 300;
    letter-spacing: 5px;
    font-size: 40px;
  }
}
.headers {
  font-family: "Oxygen";
  letter-spacing: 5px;
  font-weight: 300;
  font-size: 35px;
}
.header-fg {
  font-family: "Lato";
  font-weight: 500;
  //letter-spacing: 5px;
  text-transform: uppercase;
  font-size: 3.5em;
}
.white-filter {
  background-color: rgba(126, 123, 123, 0.41);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}
</style>

<style lang="scss" media="screen">
@font-face {
  font-family: "neon";
  src: url("../assets/fonts/beon.ttf");
}
.v-breadcrumbs__item {
  font-family: "Lato";
  font-size: 17px;
}
.neon {
  letter-spacing: 7px !important;
  font-family: "neon" !important;
  background-color: rgba(0, 0, 0, 0.6);
  width: 450px;
  text-shadow: 0 0 10px rgba(255, 255, 255, 1), 0 0 20px rgba(255, 255, 255, 1),
    0 0 30px rgba(255, 255, 255, 1), 0 0 40px rgb(106, 168, 219), 0 0 70px rgb(106, 168, 219),
    0 0 80px rgb(106, 168, 219), 0 0 100px rgb(106, 168, 219);
}
</style>
