<template>
  <v-app>
    <v-app-bar app color="dark" dark style="z-index:2000">
      <div class="d-flex align-center">
        <v-img
          class="shrink mr-0"
          contain
          :src="require('./assets/Hacktroniks.svg')"
          transition="scale-transition"
          height="55"
        />
      </div>
      <router-link class="Title ml-1" to="/">
        <h2>HACKTRONICKS</h2>
      </router-link>
      <v-spacer></v-spacer>
      <v-scroll-x-reverse-transition :hide-on-leave="true">
        <div class="options pr-3" v-show="!isSearching">
          <router-link :class="getSelected('Inicio')" class="link-container px-4" to="/">
            Inicio
          </router-link>
          <router-link
            :class="getSelected('Categoría')"
            class="link-container px-4"
            to="/categorias"
          >
            Catálogo
          </router-link>
          <router-link :class="getSelected('Catálogo')" class="link-container px-4" to="/">
            Cotizaciones
          </router-link>
          <v-icon
            class="link-container px-4 not-selected"
            v-text="'mdi-magnify'"
            @click="isSearching = true"
          />
          <v-badge color="green" :content="cart.length">
            <v-icon style="width:24px" v-text="'mdi-cart'" @click="drawer = true" />
          </v-badge>
        </div>
      </v-scroll-x-reverse-transition>
      <v-scroll-x-reverse-transition :hide-on-leave="true">
        <div class="options" v-show="isSearching">
          <v-text-field
            v-model="search"
            solo
            dense
            hide-details
            dark
            rounded
            filled
            placeholder="Buscar"
            append-icon="mdi-close"
            @keydown.enter="$router.replace({ name: 'Buscar', params: { search: search } })"
            @click:append="isSearching = false"
          />
        </div>
      </v-scroll-x-reverse-transition>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      right
      style="z-index:2000; height:100vh; position:fixed "
      class="pt-5 px-3"
    >
      <h3 class="header-4-alt bold center-text mb-3">CARRITO DE COMPRAS</h3>
      <v-divider />
      <v-list dense>
        <v-list-item class="px-0" v-for="(item, index) in cart" :key="cart._id">
          <v-list-item-avatar>
            <v-img
              @click="$router.replace({ name: 'Producto', params: { id: item._id } })"
              :src="getImage(item.image)"
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
            <v-list-item-subtitle class="xs-bold-subtitles">
              {{ "$ " + item.price }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content class="justify-end">
            <div class="d-flex align-center justify-end">
              <v-text-field
                class="centered-input"
                style="max-width: 35px;"
                hide-details
                :value="item.qty"
                dense
                disabled
              />
              <v-icon small v-text="'mdi-plus'" @click="addQty(index)" />
            </div>
            <v-btn max-width="55px" x-small text @click="removeItem(index)">
              borrar
            </v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div style="position:absolute; bottom:0; left:0; right:0;">
        <v-divider />
        <div class="d-flex px-5 py-4">
          <div style="flex:1" class="titles bold">
            Total
          </div>
          <div style="flex:1; text-align:end" class="titles underline">${{ this.total }}</div>
        </div>
        <div class="d-flex">
          <v-btn tile style="flex:1" height="55px" color="blue-grey">Ver carrito</v-btn>
          <v-btn tile style="flex:1" height="55px" color="blue">Pagar</v-btn>
        </div>
      </div>
    </v-navigation-drawer>
    <v-main>
      <router-view />
    </v-main>
    <v-footer class="pie-pagina v-flex flex-column align-center justify-center">
      <v-img
        class="shrink mr-0"
        contain
        :src="require('./assets/Hacktroniks.svg')"
        transition="scale-transition"
        height="55"
      />
      <span class="Title ">HACKTRONICKS</span>
      <span class="s-light-subtitles light">
        © &nbsp;Hacktronicks todos los derechos reservados | Diseñado por Hacktronicks Dev
        team&nbsp;
      </span>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import config from "./config";
import { NavBar } from "./components/navigator";
export default {
  name: "App",
  components: { NavBar },
  computed: {
    ...mapState(["cart"]),
    total() {
      var total = 0;
      this.cart.forEach((item, i) => {
        total += item.price * item.qty;
      });
      return total;
    }
  },
  data: () => ({
    isSearching: false,
    search: "",
    drawer: false
  }),
  watch: {
    "$route.fullPath": {
      handler: function(search) {
        this.search = "";
        this.isSearching = false;
      }
    }
  },
  methods: {
    showCart(value) {
      this.drawer = value;
    },
    getSelected: function(name) {
      return this.$route.name === name ? "selected" : "not-selected";
    },
    getImage(image) {
      return image !== undefined ? image : config.images.noImage;
    },
    addQty(index) {
      this.cart[index].qty = this.cart[index].qty + 1;
    },
    removeItem(index) {
      this.cart.splice(index, 1);
    }
  },
  mounted() {
    this.$store.dispatch("loadCategories");
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/texts";
@font-face {
  font-family: "neon";
  src: url("./assets/fonts/beon.ttf");
}
.centered-input input {
  text-align: center;
}
.pie-pagina {
  background-color: rgb(25, 25, 25) !important;
  height: 146px !important;
}
.Title {
  font-family: "neon";
  color: #ffffff !important;
  text-decoration: none !important;
}
.options {
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 380px;
}
.link-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: white !important;
  font-family: Oxygen;
}
.selected {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: white;
}
.not-selected {
}
.not-selected:hover {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: rgb(102, 170, 223);
  border-top-width: 2px;
  border-top-style: solid;
  border-top-color: #272727;
}
</style>
